// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict'
require('../_modules/top-bar/top-bar')
require('../_modules/header/header')
require('../_modules/navigation/navigation')
require('../_modules/mobile-menu/mobile-menu')
require('../_modules/dropdown-submenu/dropdown-submenu')
require('../_modules/footer/footer')
require('../_modules/hero-slider/hero-slider')

require('../_modules/block-404/block-404')
require('../_modules/block-academydetail/block-academydetail')
require('../_modules/block-agenda/block-agenda')
require('../_modules/block-agendacompany/block-agendacompany')
require('../_modules/block-campaigns/block-campaigns')
require('../_modules/block-carousel/block-carousel')
require('../_modules/block-contact/block-contact')
require('../_modules/block-dynamic-form/block-dynamic-form')
require('../_modules/block-faq/block-faq')
require('../_modules/block-form-with-address/block-form-with-address')
require('../_modules/block-form/block-form')
require('../_modules/block-job-overview/block-job-overview')
require('../_modules/block-newsletter/block-newsletter')
require('../_modules/block-productcategories/router')
require('../_modules/block-projects/block-projects')
require('../_modules/block-recent-news/block-recent-news')
require('../_modules/block-sites/block-sites')
require('../_modules/block-zoomimages/block-zoomimages')
require('../_modules/content-pointsofsale/content-pointsofsale')
require('../_modules/product-widget/product-widget')

